// src/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';  // Import axios for API requests
import './App.css';

const Chat = () => {
  const { id: currentUserId, chatid,uuid: uuid,fullname:fullname} = useParams();  // Extract the current user ID and chat room ID
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const ws = useRef(null);
  const endOfMessagesRef = useRef(null);  // Ref for scrolling

  useEffect(() => {
     // Fetch previous messages when the component mounts
    const fetchMessages = async () => {
      try {
        const response = await axios.post('https://chat.jousy.com.co/api_jousy/chat/get_messages', {            // Adjust as needed
          chat_id: chatid                  // Use the chat ID from params
        }, {
          headers: {
            'accept': 'application/json',
            'uuid-token': uuid,            // Include the uuid-token from params
            'Content-Type': 'application/json'
          }
        });

        if (response.data.success) {
          // Map the API messages to match your internal format
          const formattedMessages = response.data.data.map(msg => ({
            user: msg.user_sender_id,
            message: msg.content,
            timestamp: new Date(msg.created_at).toLocaleString()
          }));
          // Append previous messages to the current messages state
          setMessages(formattedMessages);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();


    const socketUrl = `wss://socket.jousy.com.co/ws/${chatid}/${currentUserId}`;
    ws.current = new WebSocket(socketUrl);

    ws.current.onopen = () => {
      console.log('WebSocket Client Connected');
    };

    ws.current.onmessage = (event) => {
      console.log('Received:', event.data);
      try {
        const parsedData = JSON.parse(event.data);
        setMessages(prevMessages => [...prevMessages, parsedData]);
      } catch (e) {
        console.error('Error parsing message:', e);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.current.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [currentUserId, chatid, uuid, fullname]);

  useEffect(() => {
    // Scroll to the bottom of the chat window when messages change
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = () => {
    if (input.trim()) {
      ws.current.send(JSON.stringify({
        'uuid-token': uuid,
        'message': input,
        'user': currentUserId  // Include user ID in the message payload
      }));
      setInput('');
    }
  };

  return (
    <div className="chat-container">
      <h1>{fullname}</h1>
      <div className="chat-window">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.user === currentUserId ? 'message-right' : 'message-left'}`}
          >
            <div className="message-text">
              {msg.message}
            </div>
            <div className="message-timestamp">
              {msg.timestamp}  {/* Display the formatted timestamp */}
            </div>
          </div>
        ))}
        <div ref={endOfMessagesRef} />  {/* Ref for scrolling */}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') sendMessage();
          }}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chat;
