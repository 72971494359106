// src/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './Chat';  // Import the Chat component

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* This route handles paths like /chat/user1 */}
        <Route path="/:chatid/:id/:uuid/:fullname" element={<Chat />} />
        {/* You might want to handle 404 errors */}
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
